<template>
    <div>
        <div class="top-head">
            <!-- <div class="return" @click="$router.go(-1)"><img src="@/assets/images/return.png"></div> -->
            <div class="text-center">订单</div>
            <div class="edit" @click="exit">退出</div>
        </div>
        <ul class="course-list" v-if="courseData.length>0">
            <li v-for="item in courseData" @click="$router.push({path:'/course/order/detail',query:{id:item.order_id,count:item.count}})">
                <div class="time">
                    <span>已购买</span>
                    {{formatTime(item.create_time)}}
                </div>
                <img :src="domainUrl+item.photo" alt="">
                <div class="info">
                    <div class="title ell">{{item.class_name}}</div>
                    <div class="text ell">
                        <span>{{item.count}}讲</span>
                        {{item.feature}}
                    </div>
                    <div class="detail">
                        <div class="price">¥{{item.money}}</div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="commment-none" v-else><img src="@/assets/images/none.png"><br>暂无内容</div>
    </div>
</template>
<script>
import Vue from "vue";
export default {
    data() {
        return {
            courseData: [],
            from: Vue.getUser().from,
        }
    },
    created() {
        this.getList();
    },
    methods: {
        exit() {
            if (this.from == 2) {
                window.location.href = 'http://aunt.hailuoguniang.com/#/home'
            } else if (this.from == 1) {
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                if (isiOS) {
                    window.webkit.messageHandlers.Exit.postMessage(null);
                } else if (isAndroid) {
                    SharedUtils.exitPage();
                }
            }
        },
        formatTime(time) {
            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(Number(time + "000"));
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            return y + '-' + this.addZero(m) + '-' + this.addZero(d) + ' ' + this.addZero(h) + ':' + this.addZero(mm) + ':' + this.addZero(s);
        },
        addZero(i) {
            return i < 10 ? "0" + i : i + "";
        },
        getList() {
            this.$ajax.send({
                url: "/course/order_list",
                data: { uid: Vue.getUser().uid },
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {
                        this.courseData = data.data;
                    }
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.course-list {
    padding: 88px 0 98px 0;
    li {
        border-bottom: 16px solid #f9f9f9;
        padding: 24px 32px;
        margin: 0;
        &:last-child {
            border-bottom: none;
        }
        .time {
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 16px;
            span {
                float: right;
                font-weight: bold;
            }
        }
        .detail {
            .price {
                font-size: 32px;
                float: right;
                font-weight: bold;
                color: #353535;
            }
        }
    }
}
.commment-none {
    padding: 160px 20px;
    text-align: center;
    font-size: 28px;
    color: #959595;

    img {
        width: 70%;
    }
}
</style>